import Vue from 'vue';
import Vuex from 'vuex';
import SettingModule from './modules/setting.js';
import CurrentUser from './modules/currentUser.js';
import FeatureFlags from './modules/featureFlags.js';
import SettingPlugin from './plugins/setting.js';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        setting: SettingModule,
        CurrentUser,
        FeatureFlags
    },
    state: {
        selectedLocationId: null,
        singleLocationId: null,
        publicKey: null,
        locations: [],
        descriptiveColors: [],
        priceTagTypes: [],
        campuses: [],
        suppliers: [],
        countries: [],
        packageTypes: [],
        pos: [],
        states: [],
        terms: [],
        sectionNames: [],
        rentalTerms: [],
        gmSizes: [],
        courseDepartments: [],
        courseDepartmentsImport: [],
        courses: [],
        taxItems: [],
        dcc: [],
        bindings: [],
        shippingProviders: [],
        instructors: [],
        shippingLevels: [],
        sequenceTypes: [],
        textStatus: [],
        productTypes: [],
        productVariantTypes: [],
        users: [],
        buybackBuyers: [],
        buybackBuyerGroups: [],
        buybackSessions: [],
        tenders: [],
        taxRateNames: [],
        webCatalogs: [],
        storeTimeZone: '',
        poStatus: ['Proposed', 'Open', 'Closed'],
        stockAdjustmentReasons: [],
        returnReasons: [
            'Overstock',
            'Damaged',
            'Wrong Merchandise',
            'Cancelled',
            'Rebate'
        ],
        courseLevels: [
            { value: 0, text: '' },
            { value: 1, text: '1' },
            { value: 2, text: '2' },
            { value: 3, text: '3' },
            { value: 4, text: '4' },
            { value: 5, text: '5' }
        ],
        workStatus: [
            'Not worked',
            'Work',
            'Worked',
            'Rework',
            'In Process',
            'Check',
            'Other'
        ],
        yesNoOptions: [
            { value: true, text: 'Yes' },
            { value: false, text: 'No' }
        ],
        addReplaceOptions: [
            { value: true, text: 'Add' },
            { value: false, text: 'Replace' }
        ],
        arAccountActionOptions: [
            { value: true, text: 'Link' },
            { value: false, text: 'Unlink' }
        ],
        isActiveOptions: [
            { value: true, text: 'Active' },
            { value: false, text: 'Inactive' }
        ],
        ShippingMethods: [
            { value: 1, text: 'Price' },
            { value: 2, text: 'Item' }
        ],
        openCompletedOptions: [
            { value: 'Open', text: 'Open' },
            { value: 'Completed', text: 'Completed' }
        ],
        openOption: [
            { value: 'Open', text: 'Open' }
        ],
        dollarOrPercentage: [
            { value: 'Dollar', text: 'Dollar' },
            { value: 'Percentage', text: 'Percentage' }
        ],
        courseRequestBookStatuses: [],
        buybackToSubtractOptions: [
            { value: 'None', text: 'None' },
            { value: 'Estimated', text: 'Estimated' },
            { value: 'Actual', text: 'Actual' }
        ],
        returnedRentalsToSubtractOptions: [
            { value: 'None', text: 'None' },
            { value: 'Estimated', text: 'Estimated' },
            { value: 'Actual', text: 'Actual' }
        ],
        supplierReturnPolicies: [
            { value: 1, text: 'Return Permission Required' },
            { value: 2, text: 'No Permission Required' },
            { value: 3, text: 'No Returns' }
        ],
        webhosts: [],
        brands: [],
        accountTypes: [],
        accounts: [],
        customers: [],
        posDevices: [],
        productVariants: [],
        nbcUsers: [],
        catalogStatuses: [],
        productVariantGroups:[],
        shippingCodes:[],
        timezones:[],
        rentalPeriods:[],
        jobTypes:[],
        dateOption:[],
        buybackPurchasers:[],
        chartOfAccounts:[],
        chartOfAccountsTypes:[],
        chartOfAccountsCategories:[],
        debitOrCreditOptions: [
            { value: false, text: 'Credit' },
            { value: true, text: 'Debit' }
        ],
        chartOfAccountsCategoryDescriptions:[],        
    },
    getters: {
        getSelectedLocationId(state) {
            return state.selectedLocationId;
        },
        getSingleLocationId(state) {
            return state.singleLocationId;
        },
        getLocations(state) {
            return state.locations;
        },
        getLocationById: state => id => {
            return state.locations?.find(x => x.value === id);
        },
        getDescriptiveColors(state) {
            return state.descriptiveColors;
        },
        getPriceTagTypes(state) {
            return state.priceTagTypes;
        },
        getCampuses(state) {
            return state.campuses;
        },
        getSuppliers(state) {
            return state.suppliers;
        },
        getSupplierById: state => id => {
            return state.suppliers?.find(x => x.value === id);
        },
        getCountries(state) {
            return state.countries;
        },
        getPackageTypes(state) {
            return state.packageTypes;
        },
        getPos(state) {
            return state.pos;
        },
        getStates(state) {
            return state.states;
        },
        getStateById: state => id => {
            return state.states?.find(x => x.value === id);
        },
        getTerms(state) {
            return state.terms;
        },
        getSectionNames(state) {
            return state.sectionNames;
        },
        getRentalTerms(state) {
            return state.rentalTerms;
        },
        getTermById: state => id => {
            return state.terms?.find(x => x.value === id);
        },
        getGmSizes(state) {
            return state.gmSizes;
        },
        getCourseDepartments(state) {
            return state.courseDepartments;
        },
        getCourseDepartmentsImport(state) {
            return state.courseDepartmentsImport;
        },
        getCourses(state) {
            return state.courses;
        },
        getTaxItems(state) {
            return state.taxItems;
        },
        getDcc(state) {
            return state.dcc;
        },
        getBindings(state) {
            return state.bindings;
        },
        getShippingProviders(state) {
            return state.shippingProviders;
        },
        getInstructors(state) {
            return state.instructors;
        },
        getShippingLevels(state) {
            return state.shippingLevels;
        },
        getPOStatusOptions(state) {
            return state.poStatus;
        },
        getSequenceTypes(state) {
            return state.sequenceTypes;
        },
        getProductTypes(state) {
            return state.productTypes;
        },
        getProductVariantTypes(state) {
            return state.productVariantTypes;
        },
        getStockAdjustmentReasons(state) {
            return state.stockAdjustmentReasons;
        },
        getReturnReasons(state) {
            return state.stockAdjustmentReasons;
        },
        getTextStatus(state) {
            return state.textStatus;
        },
        getCourseLevels(state) {
            return state.courseLevels;
        },
        getWorkStatus(state) {
            return state.workStatus;
        },
        getShippingMethods(state) {
            return state.ShippingMethods;
        },
        getCourseRequestBookStatuses(state) {
            return state.courseRequestBookStatuses;
        },
        getCourseRequestBookStatusByName: state => name => {
            return state.courseRequestBookStatuses?.find(x => x.text === name);
        },
        getBuybackToSubtractOptions(state) {
            return state.buybackToSubtractOptions;
        },
        getSupplierReturnPolicies(state) {
            return state.supplierReturnPolicies;
        },
        getUsers(state) {
            return state.users;
        },
        getBuybackBuyers(state) {
            return state.buybackBuyers;
        },
        getBuybackBuyerById: state => id => {
            return state.buybackBuyers?.find(x => x.value === id);
        },
        getBuybackBuyerGroups(state) {
            return state.buybackBuyerGroups;
        },
        getBuybackBuyerGroupById: state => id => {
            return state.buybackBuyerGroups?.find(x => x.value === id);
        },
        getBuybackSessions(state) {
            return state.buybackSessions;
        },
        getTenders(state) {
            return state.tenders;
        },
        getTaxRateNames(state) {
            return state.taxRateNames;
        },
        getWebCatalogs(state) {
            return state.webCatalogs;
        },
        getStoreTimeZone(state){
            return state.storeTimeZone;
        },
        getTenderById: state => id => {
            return state.tenders?.find(x => x.value === id);
        },
        getWebhosts(state) {
            return state.webhosts;
        },
        getBrands(state) {
            return state.brands;
        },
        getAccountTypes(state) {
            return state.accountTypes;
        },
        getAccounts(state) {
            return state.accounts;
        },
        getCustomers(state) {
            return state.customers;
        },
        getPosDevices(state) {
            return state.posDevices;
        },
        getProductVariants(state) {
            return state.productVariants;
        },
        getNbcUsers(state) {
            return state.nbcUsers;
        },
        getCatalogStatuses(state) {
            return state.catalogStatuses;
        },
         getShippingCodes(state) {
            return state.shippingCodes;
        },
        getProductVariantGroups(state) {
            return state.productVariantGroups;
        },
        getTimezones(state) {
            return state.timezones;
        },
        getRentalPeriods(state) {
            return state.rentalPeriods;
        },
        getJobTypes(state) {
            return state.jobTypes;
        },
        getDateOption(state) {
            return state.dateOption;
        },
        getPublicKey(state) {
            return state.publicKey;
        },
        getBuybackPurchasers(state) {
           return state.buybackPurchasers;
        },
        getChartOfAccounts(state) {
            return state.chartOfAccounts;
        },
        getChartOfAccountsTypes(state) {
            return state.chartOfAccountsTypes;
        },     
        getChartOfAccountsCategories(state) {
            return state.chartOfAccountsCategories;
        },
        getDebitOrCreditOptions(state) {
            return state.debitOrCreditOptions;
        },
        getChartOfAccountsCategoryDescriptions(state) {
            return state.chartOfAccountsCategoryDescriptions;
        },       
    },
    mutations: {
        updateSelectedLocationId(state, payload) {
            state.selectedLocationId = payload;
        },
        updateSingleLocationId(state, payload) {
            state.singleLocationId = payload;
        },
        updateLocations(state, payload) {
            state.locations = payload;
        },
        updateDescriptiveColors(state, payload) {
            state.descriptiveColors = payload;
        },
        updatePriceTagTypes(state, payload) {
            state.priceTagTypes = payload;
        },
        updateCampuses(state, payload) {
            state.campuses = payload;
        },
        updateSuppliers(state, payload) {
            state.suppliers = payload;
        },
        updateCourseDepartments(state, payload) {
            state.courseDepartments = payload;
        },
        updateCourseDepartmentsImport(state, payload) {
            state.courseDepartmentsImport = payload;
        },
        updateCourses(state, payload) {
            state.courses = payload;
        },
        updateCountries(state, payload) {
            state.countries = payload;
        },
        updatePackageTypes(state, payload) {
            state.packageTypes = payload;
        },
        updatePos(state, payload) {
            state.pos = payload;
        },
        updateStates(state, payload) {
            state.states = payload;
        },
        updateTerms(state, payload) {
            state.terms = payload;
        },
        updateSectionNames(state, payload) {
            state.sectionNames = payload;
        },
        updateRentalTerms(state, payload) {
            state.rentalTerms = payload;
        },
        updateGmSizes(state, payload) {
            state.gmSizes = payload;
        },
        updateTaxItems(state, payload) {
            state.taxItems = payload;
        },
        updateDcc(state, payload) {
            state.dcc = payload;
        },
        updateBindings(state, payload) {
            state.bindings = payload;
        },
        updateShippingProviders(state, payload) {
            state.shippingProviders = payload;
        },
        updateInstructors(state, payload) {
            state.instructors = payload;
        },
        updateShippingLevels(state, payload) {
            state.shippingLevels = payload;
        },
        updateSequenceTypes(state, payload) {
            state.sequenceTypes = payload;
        },
        updateProductTypes(state, payload) {
            state.productTypes = payload;
        },
        updateProductVariantTypes(state, payload) {
            state.productVariantTypes = payload;
        },
        updateTextStatus(state, payload) {
            state.textStatus = payload;
        },
        updateUsers(state, payload) {
            state.users = payload;
        },
        updateBuybackBuyers(state, payload) {
            state.buybackBuyers = payload;
        },
        updateBuybackBuyerGroups(state, payload) {
            state.buybackBuyerGroups = payload;
        },
        updateBuybackSessions(state, payload) {
            state.buybackSessions = payload;
        },
        updateTenders(state, payload) {
            state.tenders = payload;
        },
        updateTaxRateNames(state, payload) {
            state.taxRateNames = payload;
        },
        updateWebCatalogs(state, payload) {
            state.webCatalogs = payload;
        },
        updateStoreTimeZone(state, payload) {
            state.storeTimeZone = payload;
        },
        updateWebhosts(state, payload) {
            state.webhosts = payload;
        },
        updateBrands(state, payload) {
            state.brands = payload;
        },
        updateAccountTypes(state, payload) {
            state.accountTypes = payload;
        },
        updateAccounts(state, payload) {
            state.accounts = payload;
        },
        updateCustomers(state, payload) {
            state.customers = payload;
        },
        updateShippingMethods(state, payload) {
            state.ShippingMethods = payload;
        },
        updatePosDevices(state, payload) {
            state.posDevices = payload;
        },
        updateProductVariants(state, payload) {
            state.productVariants = payload;
        },
        updateCourseRequestBookStatuses(state, payload) {
            state.courseRequestBookStatuses = payload;
        },
        updateNbcUsers(state, payload) {
            state.nbcUsers = payload;
        },
        updateCatalogStatuses(state, payload) {
            state.catalogStatuses = payload;
        },
        updateProductVariantGroups(state, payload) {
            state.productVariantGroups = payload;
        },
        updateShippingCodes(state, payload) {
            state.shippingCodes = payload;
        },
        updateTimezones(state, payload) {
            state.timezones = payload;
        },
        updateRentalPeriods(state, payload) {
            state.rentalPeriods = payload;
        },
        updateJobTypes(state, payload) {
            state.jobTypes = payload;
        },
        updateDateOption(state, payload) {
            state.dateOption = payload;
        },
        updatePublicKey(state, payload) {
            state.publicKey = payload;
        },
        updateBuybackPurchasers(state, payload) {
            state.buybackPurchasers = payload;
        },
        updateChartOfAccounts(state, payload){
            state.chartOfAccounts = payload;
        },        
        updateChartOfAccountsTypes(state, payload){
            state.chartOfAccountsTypes = payload;
        },
        updateChartOfAccountsCategories(state, payload){
            state.chartOfAccountsCategories = payload;
        },
        updateChartOfAccountsCategoryDescriptions(state, payload){
            state.chartOfAccountsCategoryDescriptions = payload;
        },
        updateStockAdjustmentReasons(state, payload) {
            state.stockAdjustmentReasons = payload;
        }        
    },
    actions: {
        UpdateMutation ({commit}, payload){
            commit(payload.name, payload.data);
        }
    },
    plugins: [SettingPlugin]
});
